import { TextField } from '@material-ui/core'
import propTypes from 'prop-types'
import React, { Fragment } from 'react'
import NumberFormat from 'react-number-format'

function FormatCEP(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
            formattedValue: values.formattedValue,
          },
        })
      }}
      format="#####-###"
    />
  )
}

FormatCEP.propTypes = {
  inputRef: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
}

const CEPInput = (props) => {
  return (
    <Fragment>
      <TextField InputProps={{ inputComponent: FormatCEP }} {...props} />
    </Fragment>
  )
}

export default CEPInput
