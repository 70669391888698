import React, { useEffect } from 'react';
import { AppBar, Button, Container, Dialog as DialogMaterial, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        alignItems: 'stretch',
        height: 88
      },
      title: {
        marginLeft: theme.spacing(4),
        flex: 1,
      },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dialog({title, open, Content, onClose, ...rest}) {
    const classes = useStyles(rest);

    const [openDialog, setOpenDialog] = React.useState(open);

    useEffect(() => {
        setOpenDialog(open);
    }, [open, Content]);

    const handleClose = () => {
        onClose(false);
        setOpenDialog(false);
    };

    return (
        <div>
            <DialogMaterial
                open={open}
                onClose={handleClose}
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h5" component="h6" className={classes.title}>
                            {title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="md">
                    {Content}
                </Container>
            </DialogMaterial>
        </div>
    );
}