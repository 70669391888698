import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import PaymentIcon from '@material-ui/icons/Payment'
import SubtitlesIcon from '@material-ui/icons/Subtitles'
import React, { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    maxWidth: 600,
  },
  label: {
    backgroundColor: '#FFF',
    width: 'calc(100% + 12px)',
    borderRadius: 14,
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '& .MuiFormControlLabel-label': {
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      transition: 'transform .2s',
      '&:hover': {
        transform: 'scale(1)',
      },
    },
  },
  icon: {
    borderRadius: '50%',
    width: 28,
    height: 28,
    backgroundColor: theme.palette.background.default,
  },
  iconPay: {
    color: theme.palette.text.secondary,
  },
  checkIcon: {
    borderRadius: '50%',
    width: 28,
    height: 28,
    backgroundColor: '#FFF',
    color: '#000',
  },
}))

const useStylesSelected = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    maxWidth: 600,
  },
  label: {
    backgroundColor: theme.palette.primary.main,
    width: 'calc(100% + 12px)',
    borderRadius: 14,
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '& .MuiFormControlLabel-label': {
      textTransform: 'uppercase',
      color: theme.palette.primary.contrastText,
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      transition: 'transform .2s',
      '&:hover': {
        transform: 'scale(1)',
      },
    },
  },
  icon: {
    borderRadius: '50%',
    width: 28,
    height: 28,
    backgroundColor: theme.palette.background.default,
  },
  iconPay: {
    color: theme.palette.secondary.main,
  },
  checkIcon: {
    borderRadius: '50%',
    width: 28,
    height: 28,
    backgroundColor: '#FFF',
    color: '#000',
  },
}))

const ListRadio = ({ itens, value, changeValue, ...rest }) => {
  const classes = useStyles(rest)

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const classesSelected = useStylesSelected(rest)
  const [select, setSelect] = React.useState(value || '')

  useEffect(() => {
    setSelect(value || '')
  }, [value])

  const handleChange = (event) => {
    setSelect(event.target.value)
    changeValue(event.target.value)
  }

  return (
    <FormControl fullWidth className={classes.root}>
      <RadioGroup value={select} onChange={handleChange}>
        <Grid container spacing={3} justify={mobile ? 'center' : 'flex-start'}>
          {itens.map((item, index) => {
            return (
              <Grid key={index} item xs={12} sm={6}>
                <FormControlLabel
                  value={item.value}
                  control={
                    <Radio
                      icon={<span className={item.value == select ? classesSelected.icon : classes.icon} />}
                      checkedIcon={
                        <DoneRoundedIcon
                          className={item.value == select ? classesSelected.checkIcon : classes.checkIcon}
                        />
                      }
                    />
                  }
                  label={
                    item.icon == 'cc' ? (
                      <>
                        <PaymentIcon
                          className={item.value == select ? classesSelected.iconPay : classes.iconPay}
                          style={{ marginTop: '-3px' }}
                        />{' '}
                        {item.label}{' '}
                      </>
                    ) : (
                      <>
                        <SubtitlesIcon
                          className={item.value == select ? classesSelected.iconPay : classes.iconPay}
                          style={{ marginTop: '-3px' }}
                        />{' '}
                        {item.label}
                      </>
                    )
                  }
                  checked={item.value == select}
                  labelPlacement="end"
                  className={item.value == select ? classesSelected.label : classes.label}
                />
              </Grid>
            )
          })}
        </Grid>
      </RadioGroup>
    </FormControl>
  )
}

export default ListRadio
