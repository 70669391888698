import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

const useStyles = makeStyles((theme) => ({
  btnGroup: props => ( {
    borderRadius: props.scheme == 'cart' ? 9 : 14,
    height: props.scheme == 'cart' ? 53 : 'initial',
    [theme.breakpoints.down('sm')]: {
      height: props.scheme == 'cart' ? 43 : 'initial',
    },
  }),
  btnControls: props => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    border: 0,
    fontSize: '1.4rem',
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: props.scheme == 'cart' ? 9 : 14,
    minWidth: props.scheme == 'cart' ? 15 : 40,
    width: props.scheme == 'cart' ? 20 : 'initial',
    padding: props.scheme == 'cart' ? '0 13px' : '7px 21px',
    '&:hover':{
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    '&:focus':{
      outline: 'none',
    }
  }),
  displayCounter: props => ({
    //minWidth: 100,
    border: 'none !important',
    backgroundColor: theme.palette.success.main,
    '& input':{
      textAlign: 'center',
      width: '100%',
      fontSize: props.scheme == 'cart' ? '2.2rem' : '1.5rem',
      color: theme.palette.success.contrastText,
      padding: props.scheme == 'cart'  ? theme.spacing(1,0) : theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        fontSize: props.scheme == 'cart' ? '1.6rem' : '1.5rem',
      },
    },
    '&> .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      outline: 'none',
      border: 0
    },
    '&> .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':{
      outline: 'none',
      border: 0
    }
  })
}));


const NumberButtonsInput = ({minValue=1, value=500, onChange, fullWidth, index, ...props}) => {
  const classes = useStyles(props);
  const [counter, setCounter] = useState(value);
  const [min, setMin] = useState(minValue);

  useEffect(() => {
    setMin(minValue);
  },[minValue]);

  useEffect(() => {
    setCounter(value);
  },[value]);


  const handleIncrement = () => {
    let num = parseFloat(counter) + 1;
    setCounter(num);
    onChange(num, index);
  };

  const handleDecrement = () => {
    let num = parseFloat(counter) > min ? parseFloat(counter) - 1 : min;
    setCounter(num);
    onChange(num, index);
  };

  const handleInput = (e) => {
    //let num = parseInt(e.target.value.replace(/\D/g, ""));
    let num = e.target.value;

    //if(num && num !== ''){
      setCounter(num);
      /* if(num >= min)
        onChange(num, index); */
    //}
    
  }

  const handleBlurInput = (e) => {
    let num = parseInt(e.target.value.replace(/\D/g, ""));
    num = num >= min ? num : min;

    setCounter(num);
    onChange(num, index);
  }


  const displayCounter = counter > 0;

  return (
    <Fragment>
      <ButtonGroup className={classes.btnGroup} size="large" >
        <Button className={classes.btnControls} onClick={handleDecrement}>{props.scheme === 'cart' ? <RemoveRoundedIcon fontSize="small" /> : <RemoveIcon />}</Button>
          <TextField 
            color="primary" 
            size="medium" 
            fullWidth={fullWidth} 
            className={classes.displayCounter} 
            value={counter} 
            onChange={handleInput} 
            onBlur={handleBlurInput} 
          />
        <Button className={classes.btnControls} onClick={handleIncrement}>{props.scheme === 'cart' ? <AddRoundedIcon fontSize="small" /> : <AddIcon />}</Button>
      </ButtonGroup>
      {counter <= min && <Typography variant="caption">A quantidade mínima é {min}.</Typography>}
    </Fragment>
    

  );
}

export default NumberButtonsInput;