import { Backdrop as BackdropMaterial, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const Backdrop = ({open}) => {
    const classes = useStyles();

    return(
        <BackdropMaterial className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </BackdropMaterial>
    )
}

export default Backdrop;

