import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  label:  {
    fontSize: '0.8rem',
    textAlign: 'left',
    color: '#989898'
  },
}));

const CheckboxAcceptNews = ({
  name,
  label="Confirmo que aceito receber conteúdos exclusivos da Drika e compreendo que posso me descadastrar dessa comunicação a qualquer momento de acordo com os Termos de Uso e a Política de Privacidade",
  value,
  onChange
}) => {

  const classes = useStyles();

  return(
    <FormControlLabel
      control={
        <Checkbox
            //checked={state.checkedB}
            onChange={onChange}
            name={name}
            color="primary"
            value={value}
        />
      }
      label={label}
      classes={{
        label: classes.label
      }}
    />
  )
}

export default CheckboxAcceptNews;
