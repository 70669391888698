import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { Fragment, useContext, useEffect } from 'react'
import CartContext from '../../contexts/Cart'
import UserContext from '../../contexts/User'
import AsaasCreditCardSchema from '../../schemas/AsaasCreditCardSchema'
import {
  CEPInput,
  CPFInput,
  CreditCardCVVInput,
  CreditCardInput,
  CreditCardValidateInput,
  PhoneInput,
  Select,
} from '../Inputs'
import CPForCNPJInput from '../Inputs/CPForCNPJInput'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textInput: {
    '& div input': {
      fontSize: '1.4rem',
      //color: '#E0E0E0',
    },
  },
}))

const FormAsaasCreditCard = ({ invoiceValue, getData, errors, onChangeValues, totalOrder, ...props }) => {
  const usuario = useContext(UserContext)
  const cartData = useContext(CartContext)

  const classes = useStyles(props)

  const formik = useFormik({
    initialValues: {
      number: '',
      validate: '',
      cvv: '',
      holderName: '',
      holderCPF: '',
      installmentCount: 1,
      totalValue: invoiceValue,
      saveCC: false,
      cardToken: null,
    },
    validationSchema: AsaasCreditCardSchema(),
    onSubmit: async (values) => {
      usuario.setCreditCard(values)
    },
  })

  const { errors: formikErrors, touched, handleSubmit, setFieldValue, getFieldProps, isValid, values } = formik

  useEffect(() => {
    if (onChangeValues) {
      onChangeValues(values, isValid)
    }
    usuario.setCreditCard(values)
  }, [values, isValid, onChangeValues])

  useEffect(() => {
    setFieldValue('totalValue', invoiceValue)
  }, [invoiceValue])

  function gerarParcelas() {
    const valorMinimoParcela = 5 // Valor mínimo por parcela
    const numParcelasMaximas = totalOrder.length // Limitando o número máximo de parcelas a 12

    const parcelas = []
    for (let i = 1; i <= numParcelasMaximas; i++) {
      parcelas.push({
        text: i,
        value: i,
      })
    }
    return parcelas
  }

  const parcelas = gerarParcelas()

  return (
    <Fragment>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h6" color="initial">
                  Informações do cartão:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CreditCardInput
                  {...getFieldProps('number')}
                  label="Número do Cartão"
                  fullWidth
                  autoFocus
                  required
                  name="number"
                  className={classes.textInput}
                  error={Boolean(touched?.number && formikErrors?.number)}
                  helperText={touched.number && <span>{formikErrors?.number}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CreditCardValidateInput
                  {...getFieldProps('validate')}
                  label="Data de Validade"
                  type="text"
                  name="validate"
                  fullWidth
                  required
                  className={classes.textInput}
                  error={Boolean(touched?.validate && formikErrors?.validate)}
                  helperText={touched.validate && <span>{formikErrors?.validate}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CreditCardCVVInput
                  {...getFieldProps('cvv')}
                  label="CVC/CVV"
                  type="text"
                  name="cvv"
                  fullWidth
                  required
                  className={classes.textInput}
                  error={Boolean(touched?.cvv && formikErrors?.cvv)}
                  helperText={touched.cvv && <span>{formikErrors?.cvv}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...getFieldProps('holderName')}
                  label="Nome impresso no cartão"
                  type="text"
                  fullWidth
                  required
                  name="holderName"
                  className={classes.textInput}
                  error={Boolean(touched?.holderName && formikErrors?.holderName)}
                  helperText={touched.holderName && <span>{formikErrors?.holderName}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CPFInput
                  {...getFieldProps('holderCPF')}
                  label="CPF do títular do cartão"
                  fullWidth
                  required
                  name="holderCPF"
                  className={classes.textInput}
                  error={Boolean(touched?.holderCPF && formikErrors?.holderCPF)}
                  helperText={touched.holderCPF && <span>{formikErrors?.holderCPF}</span>}
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'none' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="savecc"
                      color="primary"
                      checked={values?.saveCC}
                      onChange={(e) => {
                        setFieldValue('saveCC', e.target.checked)
                      }}
                    />
                  }
                  label="Salvar para próximo pagamento"
                  //style={{marginLeft: 2}}
                />
              </Grid>
              <Grid
                item
                xs={12}
                //style={{marginTop: '-49px'}}
              >
                <Select
                  {...getFieldProps('installmentCount')}
                  id="installments"
                  label="Parcelas"
                  value={values?.installmentCount || 1}
                  onChange={(e) => {
                    cartData.setTotal(totalOrder[e.target.value - 1])
                    setFieldValue('installmentCount', e.target.value)
                  }}
                  name="installmentCount"
                  fullWidth
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  options={parcelas}
                  helperText={touched.installmentCount && <span>{formikErrors?.installmentCount}</span>}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h6" color="initial">
                  Informações do titular do cartão:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...getFieldProps('name')}
                  label="Nome do titular"
                  type="text"
                  fullWidth
                  required
                  name="name"
                  className={classes.textInput}
                  error={Boolean(touched?.name && formikErrors?.name)}
                  helperText={touched.name && <span>{formikErrors?.name}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...getFieldProps('email')}
                  label="Email"
                  type="text"
                  fullWidth
                  required
                  name="email"
                  className={classes.textInput}
                  error={Boolean(touched?.email && formikErrors?.email)}
                  helperText={touched.email && <span>{formikErrors?.email}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CPForCNPJInput
                  {...getFieldProps('cpfCnpj')}
                  label="CPF ou CNPJ"
                  fullWidth
                  required
                  name="cpfCnpj"
                  className={classes.textInput}
                  error={Boolean(touched?.cpfCnpj && formikErrors?.cpfCnpj)}
                  helperText={touched.cpfCnpj && <span>{formikErrors?.cpfCnpj}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CEPInput
                  {...getFieldProps('postalCode')}
                  label="CEP"
                  fullWidth
                  required
                  name="postalCode"
                  onChange={(e) => setFieldValue('postalCode', e.target.formattedValue)}
                  className={classes.textInput}
                  error={Boolean(touched?.postalCode && formikErrors?.postalCode)}
                  helperText={touched.postalCode && <span>{formikErrors?.postalCode}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...getFieldProps('addressNumber')}
                  label="Número do endereço"
                  type="number"
                  fullWidth
                  required
                  name="addressNumber"
                  className={classes.textInput}
                  error={Boolean(touched?.addressNumber && formikErrors?.addressNumber)}
                  helperText={touched.addressNumber && <span>{formikErrors?.addressNumber}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  {...getFieldProps('phone')}
                  label="Telefone"
                  fullWidth
                  name="phone"
                  className={classes.textInput}
                  error={Boolean(touched?.phone && formikErrors?.phone)}
                  helperText={touched.phone && <span>{formikErrors?.phone}</span>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  {...getFieldProps('mobilePhone')}
                  label="Número celular"
                  customFormat="(##) #####-####"
                  fullWidth
                  required
                  name="mobilePhone"
                  className={classes.textInput}
                  error={Boolean(touched?.mobilePhone && formikErrors?.mobilePhone)}
                  helperText={touched.mobilePhone && <span>{formikErrors?.mobilePhone}</span>}
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Fragment>
  )
}

export default FormAsaasCreditCard
