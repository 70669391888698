import { Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    title:{
        textTransform:'uppercase',
        marginBottom: 28
    },
  
}));

const TitleSection = (props) => {
        const classes = useStyles(props);
        
        return(
            <Fragment>
                <Grid >
                    <Typography variant="h4" component="h3" style={props.style} className={classes.title} >{props.text || 'Destaque'}</Typography>
                </Grid>
            </Fragment>
        );
}

export default TitleSection;