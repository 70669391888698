import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { getShop } from '../../services/api/shop'
import { WhatsAppButtonFloat } from '../Buttons'
import { CookieDisclaimerBanner } from '../Feedback'
import { Link } from '../Typography'
import Cookies from 'universal-cookie'
import ShopContext from '../../contexts/Shop'

const cookies = new Cookies()

const Footer = () => {
  const theme = useTheme()
  const dataShop = useContext(ShopContext)
  const [cells, setCells] = useState([])
  const [phones, setPhones] = useState([])
  const [emails, setEmails] = useState([])
  const [inscricao, setInscricao] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [bairro, setBairro] = useState('')
  const [municipio, setMunicipio] = useState('')
  const [uf, setUf] = useState('')
  const [cep, setCep] = useState('')
  const [showDisclaimerBanner, setShowDisclaimerBanner] = useState(true)

  const footerData = dataShop?.conf?.footer
  const credits = dataShop?.conf?.credits?.split('\r\n').map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    )
  })

  const loadShop = async () => {
    try {
      let contacts = await getShop()
      let cells = contacts.data.celulares
      let phones = contacts.data.telefones
      let emails = contacts.data.emails
      let inscricao = contacts.data.pessoa.juridica.inscricao_estadual
      let logradouro = contacts.data.logradouro
      let numero = contacts.data.numero
      let complemento = contacts.data.complemento
      let bairro = contacts.data.bairro
      let municipio = contacts.data.municipio
      let uf = contacts.data.uf
      let cep = contacts.data.cep

      setCells(cells)
      setPhones(phones)
      setEmails(emails)
      setInscricao(inscricao)
      setLogradouro(logradouro)
      setNumero(numero)
      setComplemento(complemento)
      setBairro(bairro)
      setMunicipio(municipio)
      setUf(uf)
      setCep(cep)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    loadShop()
    const acceptCookies = cookies.get('acceptPlcyTerms')
    setShowDisclaimerBanner(acceptCookies == '1' ? false : true)
  }, [])

  const handleButton = () => {
    cookies.set('acceptPlcyTerms', 1)
    setShowDisclaimerBanner(false)
  }

  const year = new Date().getFullYear()

  return (
    <Fragment>
      <footer
        className="footer pt-5"
        style={Boolean(footerData?.length) ? { backgroundColor: theme.palette.footer.background } : {}}
      >
        <Container>
          <Grid container spacing={3} direction="row" justifyContent="space-between">
            {footerData?.map((item) => (
              <Grid item xs={12} sm={3}>
                <h3 style={{color: theme.palette.footer.text}}>{item.title}</h3>
                <ul className="nav flex-column">
                  {item.content?.map((link) => (
                    <li className="nav-item">
                      <Link href={link?.link} text={link?.text} target={link?.target} scheme="footerSecondary" />
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
        </Container>
        {Boolean(dataShop?.conf?.credits) && (
          <div className="mt-5" style={{ backgroundColor: theme.palette.credits.background, color: theme.palette.credits.text  }}>
            <div className="container">
              <div className="row pt-3 d-flex justify-content-center">
                <p className="text-center">{credits}</p>
              </div>
            </div>
          </div>
        )}
      </footer>
      {/* <WhatsAppButtonFloat /> */}
      {showDisclaimerBanner && <CookieDisclaimerBanner onClickButton={handleButton} />}
      <WhatsAppButtonFloat />
    </Fragment>
  )
}

export default Footer
