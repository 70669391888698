import React, { Fragment, useContext, useEffect, useState } from "react";
import { makeStyles, Divider, Typography, CircularProgress } from "@material-ui/core";

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { logout } from "../../services/api/auth";
import { Link } from "../Typography";
import CartNotify from "./CartNotify";
import { NavLink, useHistory } from "react-router-dom";
import UserContext from "../../contexts/User";
import ShopContext from "../../contexts/Shop";

const useStyles = makeStyles((theme) => ({
    root:{
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    icon: {
        width: 32,
        height: 32,
    },
    menu: {
        padding: 21,
        backgroundColor: 'rgba(0,0,0,0.5)',
        '&>div>ul': {
            minWidth: 250
        },
    },
    menuItens: {
        color: theme.palette.header.text,
        fontSize: '1.2rem',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.header.textSencondary
        }
    },
    userLoginButton: {
        color: theme.palette.header.text,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:hover>span>svg': {
            color: theme.palette.header.textSencondary
        },
        '&:hover>span>h6': {
            color: theme.palette.header.textSencondary
        },
    },
    userLoginIcon: {
        color: theme.palette.header.text,
        '&:hover': {
            color: theme.palette.header.textSencondary
        }
    },
    userLoginName: {
        '&:hover': {
            color: theme.palette.header.textSencondary
        }
    },
    userLoginIconItem: {
        color: theme.palette.header.text,
        marginRight: theme.spacing(1)
    },
    userLoginNameItem: {
        color: theme.palette.header.text,
    },
    navlink: {
        '&:hover':{
            textDecoration: 'none',
        }
    }
}));

const DropdownMenuUser = (props) => {
    const user = useContext(UserContext);
    
    const classes = useStyles(props);
    const [userLogged, setUserLogged] = useState(user.logged);
    const [anchorElMenuUser, setAnchorElMenuUser] = useState(null);
    const history = useHistory();
    const dataShop = useContext(ShopContext)
    const dataShopGeral = dataShop.conf.geral.checkout_fixed == 0
    ? <Link scheme="header" href="/registro" text="Crie sua conta" />
    : '';


   useEffect(() => {
        async function isLogged(data) {
            setUserLogged(data.logged);
        }
        user.subscribe(isLogged);

        return function cleanup() {
            user.unsubscribe(isLogged);
        }
    }, [user]);

    const handleOpenMenuUser = (event) => {
        setAnchorElMenuUser(event.currentTarget);
    }

    const handleCloseMenuUser = () => {
        setAnchorElMenuUser(null);
    };

    const handleLogout = () => {
        user.logout();
        setUserLogged(false);
        history.push('/');
    }

    if(userLogged === true){
        return (
            <div className={classes.root} >
                <IconButton edge="start" className={classes.userLoginButton} aria-controls="dk-menu-profile" aria-haspopup="true" onClick={handleOpenMenuUser}   >
                    <AccountCircleOutlinedIcon fontSize="large" className={classes.userLoginIcon} />
                    <Typography variant="h6" className={classes.userLoginName} >
                        {user.pessoa ? user.pessoa.nome.split(' ').slice(0, 1).join(' ') : <CircularProgress />}
                    </Typography>
                </IconButton>
                <Menu
                    id="dk-menu-profile"
                    anchorEl={anchorElMenuUser}
                    keepMounted
                    open={Boolean(anchorElMenuUser)}
                    onClose={handleCloseMenuUser}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className={classes.menu}
                >
                    <MenuItem className={classes.menuItens}>
                        <AccountCircleOutlinedIcon fontSize="large" className={classes.userLoginIconItem} />
                        <Typography variant="h6" className={classes.userLoginNameItem} >
                            Olá {user.pessoa ? user.pessoa.nome.split(' ').slice(0, 2).join(' ') : <CircularProgress />}
                        </Typography>
                    </MenuItem>
                    <Divider />
                    <NavLink to="/conta" className={classes.navlink}>
                        <MenuItem className={classes.menuItens} component="a" onClick={handleCloseMenuUser}>Meus Dados</MenuItem>
                    </NavLink>
                    <NavLink to="/compras" className={classes.navlink}>
                        <MenuItem className={classes.menuItens} component="a" onClick={handleCloseMenuUser}>Meus Pedidos</MenuItem>
                    </NavLink>
                    <Divider />
                    <MenuItem className={classes.menuItens} onClick={handleLogout}>Sair</MenuItem>
                </Menu>
                <CartNotify />
            </div>
        )
    }

    if(userLogged === false){
        return (
            <div className={classes.root}>
               {dataShopGeral}
                <Link scheme="header" href="/entrar" text="Entre" />
                <CartNotify />
            </div>
        )
    }

    return (
        <CircularProgress color="primary" thickness={2.5} />
    )
};

export default DropdownMenuUser;