import { validateCNPJ, validateCPF } from 'validations-br'
import * as Yup from 'yup'

const AsaasCreditCardSchema = () =>
  Yup.object().shape({
    number: Yup.string().required('O número do cartão de crédito é obrigatório'),
    validate: Yup.string().required('A data de validade é obrigatória'),
    cvv: Yup.string()
      .required('O CVV é obrigatório')
      .matches(/^\d{3,4}$/, 'CVV inválido'),
    holderName: Yup.string().required('O nome do titular é obrigatório'),
    holderCPF: Yup.string()
      .required('O CPF do titular é obrigatório')
      .test('cpf', 'CPF inválido', (value) => {
        return validateCPF(value)
      }),
    installmentCount: Yup.number()
      .required('O número de parcelas é obrigatório')
      .positive('O número de parcelas deve ser positivo'),
    totalValue: Yup.number().required('O valor total é obrigatório').positive('O valor total deve ser positivo'),
    name: Yup.string().required('O nome é obrigatório'),
    email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
    cpfCnpj: Yup.string()
      .required('O CPF/CNPJ é obrigatório')
      .test('cpfCnpj', 'CPF ou CNPJ inválido', (value) => {
        // Verifica se o número tem 11 dígitos (CPF) ou 14 dígitos (CNPJ)
        return validateCNPJ(value) || validateCPF(value)
      }),
    postalCode: Yup.string()
      .matches(/^\d{5}-\d{3}$/, 'CEP inválido')
      .required('O CEP é obrigatório'),
    addressNumber: Yup.number().required('O número do endereço é obrigatório'),
    phone: Yup.string().test({
      test: function (value) {
        const mobilePhone = this.parent.mobilePhone
        return !!value || !!mobilePhone
      },
      message: 'Número de telefone ou celular é obrigatório',
    }),
    mobilePhone: Yup.string().test({
      test: function (value) {
        const phone = this.parent.phone
        return !!value || !!phone
      },
      message: 'Número de telefone ou celular é obrigatório',
    }),
  })

export default AsaasCreditCardSchema
