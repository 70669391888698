//import ReactGA from 'react-ga4'

const ga4ID = document.getElementById('ga4-tag-loja-dk')?.value || null
const defaultCurrency = 'BRL'
function initialize() {
  reset()
  //if (ga4ID) ReactGA.initialize(ga4ID)
}

const noDataLayer = typeof dataLayer === 'undefined' || !dataLayer

function reset() {
  //ReactGA.reset();
  if (noDataLayer) return
  dataLayer.push({ ecommerce: null })
}

function mapProduto(produto, listName, index) {
  return {
    item_name: produto.nome, // Name or ID is required.
    item_id: produto.codigo,
    price: parseFloat(produto.valor_unitario),
    //item_brand: '',
    item_category: produto.categoria,
    item_category2: produto.subcategoria,
    //item_category3: 'Shirts',
    //item_category4: 'Tshirts',
    //item_variant: 'Black',
    //item_list_name: listName || undefined, // If associated with a list selection.
    //item_list_id: listName || undefined, // If associated with a list selection.
    //index: index || undefined, // If associated with a list selection.
    quantity: parseInt(produto.quantidade ?? produto.quantidade_padrao),
  }
}

function mapListProduto(produtos, listName) {
  return produtos.map((produto, index) => {
    return {
      item_name: produto.nome, // Name or ID is required.
      item_id: produto.codigo,
      price: parseFloat(produto.valor_unitario),
      //item_brand: '',
      item_category: produto.categoria,
      item_category2: produto.subcategoria,
      //item_category3: 'Shirts',
      //item_category4: 'Tshirts',
      //item_variant: 'Black',
      //item_list_name: listName || undefined, // If associated with a list selection.
      //item_list_id: listName || undefined, // If associated with a list selection.
      //index: index, // If associated with a list selection.
      quantity: parseInt(produto.quantidade ?? produto.quantidade_padrao),
    }
  })
}

function viewProduct(produto) {
  if (noDataLayer) return
  initialize()
  /* ReactGA.event({
    category: 'Ecommerce',
    action: 'view_item',
    label: "View Product " + produto.codigo || 'Produto Sablier',
    //value: produto.nome,
    ecommerce: {
      items: [mapProduto(produto)],
    }
  }); */
  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency: defaultCurrency,
      value: mapProduto(produto).price * mapProduto(produto).quantity,
      items: [mapProduto(produto)],
    },
  })
}

function viewListProduct(produtos, listName) {
  if (noDataLayer) return

  initialize()
  if (produtos.length && produtos.length > 0) {
    /* ReactGA.event({
      category: 'Ecommerce',
      action: 'view_item_list',
      label: 'View List ' + listName,
      //value: listName,
      ecommerce: {
        items: mapListProduto(produtos, listName),
      },
    }) */
    dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: listName || undefined,
        item_list_name: listName || undefined,
        items: mapListProduto(produtos, listName),
      },
    })
  }
}

function addToCart(produto) {
  if (noDataLayer) return

  initialize()
  /* ReactGA.event({
    category: 'Ecommerce',
    action: 'add_to_cart',
    label: "Add Cart " + produto.codigo || 'Produto Sablier',
    //value: produto.nome,
    ecommerce: {
      items: [mapProduto(produto)],
    }
  }); */
  dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: defaultCurrency,
      value: mapProduto(produto).price * mapProduto(produto).quantity,
      items: [mapProduto(produto)],
    },
  })
}

function removeFromCart(produto) {
  if (noDataLayer) return

  initialize()
  /* ReactGA.event({
    category: 'Ecommerce',
    action: 'remove_from_cart',
    label: 'Remove Cart ' + produto.codigo || 'Produto Sablier',
    //value: produto.nome,
    ecommerce: {
      items: [mapProduto(produto)],
    },
  }); */
  dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency: defaultCurrency,
      value: mapProduto(produto).price * mapProduto(produto).quantity,
      items: [mapProduto(produto)],
    },
  })
}

function selectItem(produto, listName, index) {
  if (noDataLayer) return

  initialize()
  /* ReactGA.event({
    category: 'Ecommerce',
    action: 'select_item',
    label: 'Select Product List ' + produto.codigo || 'Produto Sablier',
    //value: produto.nome,
    ecommerce: {
      items: [mapProduto(produto, listName, index)],
    },
  }) */
  dataLayer.push({
    event: 'select_item',
    ecommerce: {
      item_list_id: listName || undefined,
      item_list_name: listName || undefined,
      items: [mapProduto(produto, listName, index)],
    },
  })
}

function beginCheckout(produtos) {
  if (noDataLayer) return

  initialize()
  /* ReactGA.event({
    category: 'Ecommerce',
    action: 'begin_checkout',
    label: 'Begin Checkout ',
    //value: undefined,
    ecommerce: {
      items: mapListProduto(produtos, 'Cart List'),
    },
  }) */
  const listProdutos = mapListProduto(produtos, 'Cart List')
  const totalCarrinho = listProdutos.reduce((acumulador, objeto) => acumulador + objeto.price * objeto.quantity, 0)
  dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency: defaultCurrency,
      value: totalCarrinho,
      items: listProdutos,
    },
  })
}

function purchase(produtos, order) {
  if (noDataLayer) return

  initialize()
  /* ReactGA.event({
    category: 'Ecommerce',
    action: 'purchase',
    label: 'Order Done ',
    //value: undefined,
    ecommerce: {
      items: mapListProduto(produtos, 'Cart List'),
    },
  }) */
  const listProdutos = mapListProduto(produtos, 'Cart List')
  const totalCarrinho = listProdutos.reduce((acumulador, objeto) => acumulador + objeto.price * objeto.quantity, 0)
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currency: defaultCurrency,
      value: totalCarrinho,
      transaction_id: order.id,
      items: listProdutos,
    },
  })
}

const GA4 = {
  initialize,
  reset,
  viewProduct,
  viewListProduct,
  addToCart,
  removeFromCart,
  selectItem,
  beginCheckout,
  purchase,
}

export default GA4
