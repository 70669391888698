import React from 'react'

import { Helmet } from 'react-helmet-async'
import ShopContext from '../contexts/Shop';

const MetaTags = ({ meta_title, canonical_url, meta_description, nome_loja, image, type, keywords }) => {
  const shop = React.useContext(ShopContext);
  return (
    <Helmet>
      {meta_title && <title>{`${meta_title} ${shop?.nome ? `| ${shop?.nome}` : '| Sablier'}`}</title>}
      {meta_description && <meta name="description" content={`${meta_description}`} />}
      {canonical_url && <link rel="canonical" href={canonical_url} />}
      {keywords && <meta name="keywords" content={keywords} />}
      // Open Graph
      <meta property="og:locale" content="pt_BR" />
      {type && <meta property="og:type" content={type} />}
      {meta_title && <meta property="og:title" content={`${meta_title} ${shop?.nome ? `| ${shop?.nome}` : '| Sablier'}`} />}
      {canonical_url && <meta property="og:url" content={canonical_url} />}
      {meta_description && <meta property="og:description" content={meta_description} />}
      {nome_loja && <meta property="og:site_name" content={nome_loja} />}
      {image && <meta property="og:image" content={image} />}
      // Twitter
      <meta name="twitter:card" content="summary_large_image" />
      {image && <meta name="twitter:image" content={image} />}
      {meta_description && <meta name="twitter:description" content={meta_description} />}
    </Helmet>
  )
}

export default MetaTags
