import { TextField } from '@material-ui/core'
import propTypes from 'prop-types'
import React, { Fragment } from 'react'
import NumberFormat from 'react-number-format'

function FormatCpfCnpj(props) {
  const { inputRef, onChange, ...other } = props

  const format = (val) => {
    if (val === '') return ''

    let newValue = val.replace(/\D/g, '') // Remove caracteres não numéricos

    // Verifica se é CPF ou CNPJ
    const isCPF = newValue.length <= 11

    // Aplica a máscara apropriada
    if (isCPF) {
      newValue = newValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else {
      newValue = newValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }

    return newValue
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format={format}
    />
  )
}

FormatCpfCnpj.propTypes = {
  inputRef: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
}

const CPForCNPJInput = (props) => {
  return (
    <Fragment>
      <TextField
        InputProps={{
          inputComponent: FormatCpfCnpj,
        }}
        {...props}
      />
    </Fragment>
  )
}

export default CPForCNPJInput
