import { useMediaQuery, useTheme } from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import React, { useContext } from 'react'
import ShopContext from '../../contexts/Shop'

const WhatsAppButtonFloat = () => {
  const shop = useContext(ShopContext)
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (shop.conf?.geral?.whatsapp && shop?.link != 'drika.com.br') {
    return (
      <a
        href={`https://wa.me/55${shop.conf.geral.whatsapp}?text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20produtos`}
        style={{
          position: 'fixed',
          width: 58,
          height: 58,
          bottom: 20,
          right: mobile ? 'auto' : 20,
          left: mobile ? 20 : 'auto',
          backgroundColor: '#25d366',
          color: '#FFF',
          borderRadius: 50,
          textAlign: 'center',
          fontSize: 30,
          boxShadow: '1px 1px 2px #888',
          zIndex: '99997',
        }}
        target="_blank"
      >
        <WhatsAppIcon style={{ marginTop: 8, fontSize: '2.5rem' }} />
      </a>
    )
  } else {
    return null
  }
}

export default WhatsAppButtonFloat
