import Api from './api.js';

export const auth = async ({email, password}) => {
    try {
        const resp = await Api.post(`/auth`, {email, password});
        /* localStorage.clear();
        localStorage.removeItem('@dkgroup/user');
        localStorage.setItem('@dkgroup/user', JSON.stringify({_id : resp.data.id})); */
        return resp;
    } catch (err) {
        throw err;
    }
}

export const isAuth = async () => {
    try {
        const user = await Api.get('/me');
        //let user = JSON.parse(localStorage.getItem('@dkgroup/user'));
        return true;
    } catch (error) {
        return false;
    }
    
}

export const logout = async () => {
    await Api.get(`/logout`);
    //localStorage.removeItem('@dkgroup/user');
    return;
}