import { Container, Grid, Link } from '@material-ui/core';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import ShopContext from '../../contexts/Shop';

const useStyles = makeStyles( theme => ({
  root: {
    backgroundColor: theme.palette.header.secondary,
    paddingTop: 49,
    paddingBottom: 49,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  logo:{
      maxWidth: 150,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 95,
      },
  },
  link:{
    color: theme.palette.header.textSencondary,
    '&:hover':{
        textDecoration:'none',
        color: theme.palette.header.text       
    }
  },
}));


const HeaderClean = (props) => {
    const classes = useStyles(props);
    const shop = React.useContext(ShopContext);

    return (
        <Fragment>
            <Grid className={classes.root}>
                <Container maxWidth="xl">
                    <Grid container xs={12} spacing={2} direction="row" justifyContent="center" alignItems="center" >
                        <Grid item xs={12} container justifyContent="center">
                            <NavLink to="/">
                                {/* <Logo className={classes.logo} /> */}
                                <img src={shop.logo} className={classes.logo}  />
                            </NavLink>
                        </Grid>
                        {/*<Grid container item xs={6} justify="flex-end" alignItems="center">
                            <Link href="/contato" className={classes.link}>
                                Contatos
                            </Link>
                        </Grid>*/}
                    </Grid>
                </Container>
            </Grid>
        </Fragment>
    )
}

export default HeaderClean;