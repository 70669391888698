import React, { Fragment, useState } from 'react';
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";


const SnackAlert = (props) => {
    const [openSnack, setOpenSnack] = useState(props.openSnack || false);

    const _handleClose  = () => {
        setOpenSnack(false);
    } 

    return (
        <Fragment>
            <Snackbar 
                open={openSnack} 
                onClose={_handleClose}
                autoHideDuration={7000} 
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}} >
                <Alert severity={props.severity} onClose={_handleClose}>
                    {props.text}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}

export default SnackAlert;