import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propTypes from 'prop-types';
import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';

function FormatNumber(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="#####"
      />
    );
}
  
FormatNumber.propTypes = {
    inputRef: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
};

const useStyles = makeStyles( theme => ({
  input: {
    width: 7 + 'ch',
    fontSize: 42,
    '& input':{
      textAlign: 'center',
      width: 7 + 'ch',
      fontSize: 42
    }
  },
}));

const VerificationCodeInput = (props) => {
    const classes = useStyles(props);
    return(
        <Fragment>
            <TextField
                className={classes.input}
                InputProps={{
                    inputComponent: FormatNumber,
                }}
                {...props}
            />
        </Fragment>
    );
}

export default VerificationCodeInput;