import { TextField } from '@material-ui/core';
import propTypes from 'prop-types';
import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';

function FormatCC(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="#### #### #### ####"
      />
    );
}
  
FormatCC.propTypes = {
    inputRef: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
};

const CreditCardInput = (props) => {
    return(
        <Fragment>
            <TextField
                InputProps={{inputComponent: FormatCC,}}
                {...props}
            />
        </Fragment>
    );
}

export default CreditCardInput;