import { Typography, Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    minHeight: 65,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    border: '1px solid rgba(0,0,0,.12)',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,.12)',
    [theme.breakpoints.down('sm')]: {
      zIndex: 2147483660,
    },
  },
  text: {
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
  button: {
    margin: theme.spacing(0, 1),
  },
}))

const CookieDisclaimerBanner = ({ onClickButton }) => {
  const classes = useStyles()

  return (
    <>
      {/* <div className={classes.root}>
        <Typography variant="body1" className={classes.text}>
          Ao navegar neste site, você aceita os cookies que usamos para melhorar sua experiência.{' '}
          <NavLink to="/politicas_de_privacidade" className={classes.link}>
            Mais informações.
          </NavLink>
        </Typography>
        <Button onClick={onClickButton} className={classes.button} variant="contained" color="secondary">
          Entendi
        </Button>
      </div> */}
    </>
  )
}

export default CookieDisclaimerBanner
