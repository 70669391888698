import { Button, CircularProgress } from '@material-ui/core';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    primary: {
        textTransform: 'none',
        fontSize: '1.3rem',
        padding: '3px 21px',
        borderRadius: 10,
        backgroundColor: theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        '&:hover': { 
            backgroundColor: theme.palette.secondary.main,
            color:theme.palette.secondary.contrastText
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem',
            lineHeight: 1,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    secondary: {
        textTransform: 'none',
        fontSize: '1.3rem',
        padding: '3px 21px',
        borderRadius: 10,
        backgroundColor: theme.palette.secondary.main,
        color:theme.palette.secondary.contrastText,
        '&:hover': { 
            backgroundColor: theme.palette.success.main,
            color:theme.palette.success.contrastText
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem',
            lineHeight: 1,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    success: {
        textTransform: 'none',
        fontSize: '1.3rem',
        padding: '3px 21px',
        borderRadius: 10,
        backgroundColor: theme.palette.success.main,
        color:theme.palette.success.contrastText,
        '&:hover': { 
            backgroundColor: theme.palette.secondary.main,
            color:theme.palette.secondary.contrastText,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem',
            lineHeight: 1,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    cart: {
        textTransform: 'none',
        fontSize: '1.5rem',
        //padding: '14px 77px',
        padding: theme.spacing(1, 6),
        borderRadius: 10,
        backgroundColor: theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        lineHeight: 1,
        '&:hover': { 
            backgroundColor: theme.palette.secondary.main,
            color:theme.palette.secondary.contrastText
        }
    },
    buttonIcon: {
        textTransform: 'none',
        fontSize: '1.5rem',
        padding: '0 3px',
        borderRadius: 10,
        backgroundColor: theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        width: 49,
        height: 49,
        '&:hover': { 
            backgroundColor: theme.palette.secondary.main,
            color:theme.palette.secondary.contrastText
        },
    },
    smallButton:{
        textTransform: 'none',
        fontSize: '0.8rem',
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: 10,
        backgroundColor: theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        '&:hover': { 
            backgroundColor: theme.palette.secondary.main,
            color:theme.palette.secondary.contrastText
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
            lineHeight: 1,
        },
    },
    catalogo: {
        textTransform: 'none',
        fontSize: '1.3rem',
        padding: '8px 64px',
        borderRadius: 10,
        backgroundColor: theme.palette.secondary.main,
        color:theme.palette.secondary.contrastText,
        '&:hover': { 
            backgroundColor: theme.palette.success.main,
            color:theme.palette.success.contrastText
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem',
            lineHeight: 1,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    svgIcon:{
        fontSize: '3.2rem'
    },
    link:{
        '&:hover':{
            color: '#FFF',
            textDecoration: 'none',
        },
        '&:focus':{
            //outline: 'none',
        },
    }
}));


const CallToAction = ({ scheme="secondary", size, text, progress, svg, href, icon, onClickButton, type, disabled, target, externalLink, ...rest}) => {
        const classes = useStyles(rest);
        
        return(
            <Fragment>
                {href && !disabled && <NavLink to={{ pathname: href, state: { from: rest.location } }} target={target} className={classes.link}>
                    <Button 
                            className={`${classes[scheme]} ${classes.link}`}
                            variant="contained" 
                            size={size || "large"} 
                            onClick={onClickButton}
                            fullWidth={rest.fullWidth}
                            type={type || 'text'}
                            disabled={disabled}
                            {...rest}
                    >
                        {progress ? <CircularProgress color={scheme === "secondary" ? "primary" : "secondary"} /> : 
                            svg ? <img className={classes.svgIcon} alt="Icone" src={svg} width={48} /> : 
                            text ? text : 'Veja mais!' }
                    </Button>
                </NavLink>}

                {(!href || disabled) &&
                    <Button 
                            className={ `${classes[scheme]} ${classes.link}`}
                            variant="contained" 
                            size={size || "large"} 
                            onClick={onClickButton}
                            fullWidth={rest.fullWidth}
                            type={type || 'text'}
                            disabled={disabled}
                            href={externalLink}
                            {...rest}
                    >
                        {progress ? <CircularProgress color={scheme === "secondary" ? "primary" : "secondary"} /> : 
                            svg ? <img className={classes.svgIcon} alt="Icone" src={svg} width={48} /> : 
                            icon ? icon :
                            text ? text : 'Veja mais!'}
                    </Button>}
                
            </Fragment>
        );
}

export default CallToAction;