import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    paddingTop: theme.spacing(3),
  },
  primary: {
    '&:hover' : {
      color: theme.palette.primary.contrastText
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  secondary: {
    '&:hover' : {
      color: theme.palette.secondary.contrastText
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function AlertDialog(props) {
  const classes = useStyles(props);

  const [open, setOpen] = React.useState(props.open);

  useEffect(()=>{
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.onClose(false);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
            <Grid container justify="space-around" alignItems="center" className={classes.buttonContainer} >
              {props.button1 && 
                  <Button component={props.button1.href ? NavLink : 'Button'} to={props.button1.href} onClick={handleClose} className={classes[props.button1.color || 'primary']} variant="contained" color={props.button1.color || 'primary'}>
                      {props.button1.text}
                  </Button>
              }
              {props.button2 && 
                  <Button component={props.button2.href ? NavLink : 'Button'} to={props.button2.href} onClick={handleClose} variant="contained" className={classes[props.button2.color || 'secondary']} color={props.button2.color || 'secondary'}>
                      {props.button2.text}
                  </Button>
              }
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}