import { useTheme } from '@material-ui/core';
import React from 'react';

const Cart = (props) => {
    return (
      <svg width={props.width || 32} height={props.height || 32} enableBackground="new 0 0 500 500" version="1.1" viewBox="0 0 500 500" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
         <path fill={props.color} d="m159.6 389.4c-28.8 0-52.2 23.4-52.2 52.2s23.4 52.2 52.2 52.2 52.2-23.4 52.2-52.2c0-28.9-23.4-52.2-52.2-52.2zm0 81.2c-16 0-29-13-29-29s13-29 29-29 29 13 29 29-13 29-29 29z"/>
         <path fill={props.color} d="m380 389.4c-28.8 0-52.2 23.4-52.2 52.2s23.4 52.2 52.2 52.2 52.2-23.4 52.2-52.2c0.1-28.9-23.3-52.2-52.2-52.2zm0 81.2c-16 0-29-13-29-29s13-29 29-29 29 13 29 29c0.1 16-12.9 29-29 29z"/>
         <path fill={props.color} d="m497.8 84.8c-2.4-2.6-5.7-4.3-9.3-4.6l-377.7-5.2-10.4-32c-7.4-21.4-27.4-35.8-49.9-36h-38.9c-6.4 0-11.6 5.2-11.6 11.6s5.2 11.6 11.6 11.6h38.9c12.6 0.3 23.7 8.4 27.9 20.3l73.6 222.2-5.8 13.3c-6.5 16.7-4.5 35.5 5.2 50.5 9.7 14.7 25.9 23.8 43.5 24.4h225.7c6.4 0 11.6-5.2 11.6-11.6s-5.2-11.6-11.6-11.6h-225.6c-9.9-0.2-19.1-5.5-24.4-13.9-5.2-8.3-6.3-18.6-2.9-27.9l4.6-10.4 244.3-25.5c26.8-3 48.9-22.5 55.1-48.7l27.9-116.6c1.2-3.4 0.6-7.2-1.8-9.9zm-48.7 121.2c-3.8 16.9-18.1 29.4-35.4 30.8l-241.4 24.9-54-163.6 355.7 5.2-24.9 102.7z"/>
      </svg>          
    )
} 

export default Cart;