import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Badge, useTheme } from '@material-ui/core';
import {ReactComponent as CartMedia} from "../../assets/icons/carrinho-compras-roxo.svg";
import { NavLink } from 'react-router-dom';
import CartContext from '../../contexts/Cart';
import Cart from '../Icons/Cart';

const CartNotify = (props) => {
    const dataCart = useContext(CartContext);
    const [qty, setQty ] = useState(dataCart.qty);
    const theme = useTheme();


    useEffect(() => {
        function updateQty(cart){
            setQty(cart.qty);
        }
        
        dataCart.subscribe(updateQty);

        return function cleanup() {
            dataCart.unsubscribe(updateQty);
        }
    }, [dataCart.qty]);



    return (
        <Fragment>
            <Badge badgeContent={qty} color="secondary" style={{ width: 32, height: 32 }}>
                <NavLink to="/carrinho">
                    <Cart color={theme.palette.header.text} width={32} height={32} />
                </NavLink>
            </Badge> 
        </Fragment>            
    )
} 

export default CartNotify;