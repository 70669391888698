import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {MenuItem, Select as SelectMaterial} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

const StyleInput = withStyles((theme) => ({
  root: {
      width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    font: 'inherit',
    color: 'currentColor',
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  input:{
    fontSize: props => props.fontSize || '1.4rem',
    paddingTop: 7,
    paddingBottom: 7,
  },
  option: {
    padding: '7px 14px !important',
    fontSize: '1.2rem',
    color: theme.palette.text.secondary,
    '&:hover':{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }
}));

export default function Select({id, value, onChange, label, fullWidth, style, options, ...props}) {
  const classes = useStyles(props);
  const styles = style ? style : fullWidth ? {width: '100%'} : {minWidth: 70};
 
  return (
    <FormControl style={styles}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <SelectMaterial
          id={id}
          value={value}
          onChange={onChange}
          className={classes.input}
          MenuProps={{
            getContentAnchorEl : null,
            anchorOrigin : {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin:{
              vertical: 'top',
              horizontal: 'center',
            }
          }}
          //input={<StyleInput />}
          {...props}
        >
          {
            options.map((option, index) => {
              return <MenuItem selected={index === 0} key={index} className={classes.option} value={option.value}>{option.text}</MenuItem> 
            })
          }
        </SelectMaterial>
      </FormControl>
  );
}