import React from 'react';
import {
  fade,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


const BootstrapInputStyle = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F5F5F5',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  control: props => ({
    margin: theme.spacing(1,0),
    width: props.fullWidth ? '100%' : 'initial',
  }),
}));

export default function BootstrapInput(props) {
  const classes = useStyles(props);
  const { id, label, ...rest} = props;
  return (
      <FormControl className={classes.control}>
          {label &&
            <InputLabel shrink htmlFor={id}>
                {label}
            </InputLabel>
          }
        <BootstrapInputStyle id={id} {...rest} fullWidth={rest.fullWidth} />
      </FormControl>
  );
}