import { TextField } from '@material-ui/core';
import propTypes from 'prop-types';
import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';

function limit(val, max) {
    if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            val = '01';

            //this can happen when user paste number
        } else if (val > max) {
            val = max;
        }
    }

    return val;
}

function cardExpiry(val) {
    let month = limit(val.substring(0, 2), '12');
    let year = val.substring(2, 4);

    return month + (year.length ? '/' + year : '');
}

function Format(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format={cardExpiry}
            mask={['M', 'M', 'Y', 'Y']}
            placeholder="MM/AA"
        />
    );
}

Format.propTypes = {
    inputRef: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
};

const CreditCardValidateInput = (props) => {
    return (
        <Fragment>
            <TextField
                InputProps={{ inputComponent: Format, }}
                {...props}
            />
        </Fragment>
    );
}

export default CreditCardValidateInput;