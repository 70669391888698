import { CircularProgress, Link as LinkMaterial } from '@material-ui/core'
import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
  primary: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.contrastText,
    },
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.contrastText,
    },
  },
  footerSecondary: {
    color: theme.palette.footer.link,
    fontSize: '1.2rem',
    '&:hover': {
      color: theme.palette.footer.text,
    },
  },
  success: {
    color: theme.palette.success.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  header: {
    color: theme.palette.header.text,
    '&:hover': {
      color: theme.palette.header.textSencondary,
    },
  },
}))

const Link = (props) => {
  const classes = useStyles(props)

  const { scheme = 'primary', text, component, href, progress, ...rest } = props
  return (
    <Fragment>
      {progress && <CircularProgress />}
      {!progress && (
        <LinkMaterial
          component={component || NavLink}
          className={`${classes[scheme]} ${classes.link}`}
          to={href ? { pathname: href, state: { from: props.location } } : '/'}
          href={href}
          {...rest}
        >
          {text || 'Clique aqui!'}
        </LinkMaterial>
      )}
    </Fragment>
  )
}

export default Link
