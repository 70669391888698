import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
   
  },
  input: {
    borderRadius: 14,
    position: 'relative',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    border: '1px solid #ced4da',
    fontSize: '1.3rem',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: theme.palette.success.main,
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  control: {
    width: '100%',
  },
  option: {
    borderRadius: 14 + '!important',
    backgroundColor: theme.palette.success.main + '!important',
    padding: '21px 7px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.main + '!important',
    },
    '&::after':{
      backgroundColor: theme.palette.primary.main + '!important',
    },
  }
}));

export default function CustomSelect(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value || '');
  const options = props.options || [{text:'Selecione', value:''}];
  
  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event);

  };
  
  return (
    <div>
      <FormControl className={classes.control}>
        <Select
          labelId="customized-select-label"
          id="customized-select"
          value={value}
          onChange={handleChange}
          input={<BootstrapInput />}
          native
        >
            {
                options.map( (option, index) => {
                    return (
                        <option key={index} className={classes.option} value={option.value}>{option.text}</option>
                    )
                })
            }
        </Select>
      </FormControl>
    </div>
  );
}