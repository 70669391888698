import {
  AppBar,
  CircularProgress,
  Collapse,
  Divider,
  Drawer,
  Grid,
  Hidden,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
  Typography,
  fade,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Link } from '../Typography'

import NestedMenuItem from '@inmotio/material-ui-nested-menu-item'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from '@material-ui/core/styles'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import { orderBy } from 'lodash'
import ShopContext from '../../contexts/Shop'
import UserContext from '../../contexts/User'
import CartNotify from './CartNotify'
import DropdownMenuUser from './DropdownMenuUser'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //width: '100vw',
  },
  grow: {
    flexGrow: 1,
  },
  bar: {
    boxShadow: 'none',
    height: '100%',
    minHeight: 130,
    paddingTop: 14,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
    backgroundColor: theme.palette.header.primary || 'transparent',
  },
  toolbar: {
    width: '100%',
    maxWidth: 1280,
  },
  logo: {
    //width: 180,
    //marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  logoImage: {
    width: 100 + '%',
    maxWidth: 180,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 120,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.header.primary || theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.header.primary || theme.palette.common.white, 0.25),
    },
    //marginRight: 0,
    //marginLeft: theme.spacing(2),
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '70%',
    position: 'absolute',
    right: 0,
    top: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '2px solid #D3D3D3',
    borderRadius: 'initial',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  inputInput: {
    padding: theme.spacing(2, 0, 2, 2),
    backgroundColor: '#F5F5F5',
    fontSize: 19,
    // vertical padding + font size from searchIcon
    paddingRight: 'calc(1em + 40px)',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0, 1, 2),
    },
    borderRadius: theme.shape.borderRadius,
    '&::placeholder': {
      color: '#D3D3D3',
    },
  },
  icon: {
    width: 32,
    height: 32,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    '&>div>a': {
      marginLeft: theme.spacing(3),
    },
    '&>div>span': {
      marginLeft: theme.spacing(3),
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menu2: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '100%',
      maxWidth: 1280,
    },
  },
  toolbar2: {
    width: '100%',
    minHeight: 25,
    marginTop: 21,
  },
  containerLinks: {
    display: 'flex',
    marginRight: theme.spacing(10),
    '&>span': {
      cursor: 'pointer',
    },
  },
  toolbarLinks: {
    color: theme.palette.header.text,
    '&:hover': {
      color: theme.palette.header.textSencondary,
      textDecoration: 'none',
    },
  },
  menu: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 0,
    padding: 14,
    '&::before': {
      width: 0,
      height: 0,
      content: '',
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderBottom: '12px solid #fff',
      top: '-12px',
      marginRight: '-12px',
      position: 'absolute',
      right: '50%',
    },
    '&>div.MuiPaper-rounded': {
      borderRadius: 0,
    },
    '&>div>ul': {
      minWidth: 200,
    },
    '&>div>ul>a': {
      color: theme.palette.header.text,
    },
    '&>div>ul>a:hover': {
      textDecoration: 'none',
    },
    '&>div>ul>a>div>li': {
      fontSize: '1.1rem',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '&>div>ul>a:hover>div>li': {
      backgroundColor: theme.palette.header.text,
      color: theme.palette.header.textSencondary,
    },
    '&>div>ul>a>div>li:hover': {
      backgroundColor: theme.palette.header.text,
      color: theme.palette.header.textSencondary,
    },
  },
  submenu: {
    borderRadius: 0,
    backgroundColor: '#333',
    '&>div.MuiPaper-rounded': {
      borderRadius: 0,
    },
    '&>div>ul': {
      minWidth: 200,
    },
    '&>div>ul>a': {
      color: theme.palette.text.primary,
    },
    '&>div>ul>a:hover': {
      textDecoration: 'none',
    },
    '&>div>ul>a>div>li': {
      fontSize: '1.1rem',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '&>div>ul>a:hover>div>li': {
      backgroundColor: theme.palette.header.text,
      color: theme.palette.header.textSencondary,
    },
    '&>div>ul>a>div>li:hover': {
      backgroundColor: theme.palette.header.text,
      color: theme.palette.header.textSencondary,
    },
  },
  submenuItem: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.header.textSencondary,
      backgroundColor: theme.palette.header.text,
    },
    '&>li:hover': {
      backgroundColor: theme.palette.header.text,
    },
  },
  userLoginIconItem: {
    color: theme.palette.header.text,
    //color: theme.palette.text.option,
    marginRight: theme.spacing(1),
  },
  userLoginNameItem: {
    color: theme.palette.header.text,
    //color: theme.palette.text.option,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  arrowIcon: {
    color: theme.palette.header.text,
  },
  nested: (pl) => ({
    paddingLeft: theme.spacing(pl),
  }),
  drawerPaper: {
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
}))

function RecursiveMenu({ data, openMenus = [], pl = 2 }) {
  const paddingLeft = pl
  const classes = useStyles(paddingLeft)
  const [menus, setMenus] = useState(openMenus)

  const handleClick = (menu) => {
    const includes = menus.includes(menu)
    if (includes) {
      setMenus((prev) => prev.filter((item) => item !== menu))
    } else {
      setMenus((prev) => [...prev, menu])
    }
  }

  return (
    <>
      {data?.map((item) => (
        <>
          <ListItem
            button
            component="a"
            href={item.link || undefined}
            target={item.target}
            className={classes.nested}
            key={item.title}
          >
            <ListItemText primary={item.title} />
            <ListItemSecondaryAction>
              {Boolean(item?.children?.length) ? (
                <IconButton size="small" edge="end" onClick={() => handleClick(item.title)}>
                  {menus?.includes(item.title) ? (
                    <ExpandLess className={classes.arrowIcon} fontSize="12" />
                  ) : (
                    <ExpandMore className={classes.arrowIcon} fontSize="12" />
                  )}
                </IconButton>
              ) : null}
            </ListItemSecondaryAction>
          </ListItem>
          {item?.children && (
            <Collapse in={menus?.includes(item.title)} timeout="auto" unmountOnExit>
              <RecursiveMenu data={item?.children} openMenus={menus} pl={paddingLeft + 2} />
            </Collapse>
          )}
        </>
      ))}
    </>
  )
}

const Header = ({ termSearch }) => {
  const theme = useTheme()
  const classes = useStyles()
  const dataShop = useContext(ShopContext)
  const User = useContext(UserContext)

  const [userLogged, setUserLogged] = useState(User.logged)
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false)
  const [menuPosition, setMenuPosition] = useState(null)
  const [anchorEl] = useState(null)
  const [term, setTerm] = useState(termSearch || '')
  const history = useHistory()

  const menu = dataShop?.conf?.menu
  const displayMenu = menu?.length > 7 ? menu.slice(0, 7) : menu
  const dataShopGeral =
    dataShop.conf.geral.checkout_fixed == 0 ? <Link scheme="header" href="/registro" text="Crie sua conta" /> : ''

  useEffect(() => {
    function isLogged(data) {
      setUserLogged(data.logged)
    }
    User.subscribe(isLogged)

    return function cleanup() {
      User.unsubscribe(isLogged)
    }
  }, [User.logged])

  const toggleDrawer = (open) => {
    setMenuDrawerOpen(open)
  }

  const handleMobileMenuOpen = () => {
    setMenuDrawerOpen(!menuDrawerOpen)
  }

  const handleLogout = () => {
    User.logout()
    setUserLogged(false)
    history.push('/')
  }

  const DrawerList = () => {
    const menu = orderBy(
      (dataShop?.conf?.menu || []).map((item, index) => ({
        ...item,
        index,
        hasSubmenu: item.children && item.children?.length > 0,
      })),
      ['hasSubmenu', 'index'],
      ['asc', 'asc']
    )

    return (
      <div role="presentation">
        <List>
          <ListItem button>
            {userLogged === true ? (
              <>
                <AccountCircleOutlinedIcon fontSize="large" className={classes.userLoginIconItem} />
                <Typography variant="h6" className={classes.userLoginNameItem}>
                  Olá {User.pessoa ? User.pessoa.nome.split(' ').slice(0, 2).join(' ') : <CircularProgress />}
                </Typography>
              </>
            ) : (
              dataShopGeral
            )}
          </ListItem>
          <ListItem button>
            {userLogged === true ? (
              <Link scheme="header" text="Sair" onClick={handleLogout} />
            ) : (
              <Link scheme="header" href="/entrar" text="Entre" />
            )}
          </ListItem>
          <Divider />
          {/* Mobile sidebar links */}
          {menu?.map((item) => (
            <>
              {!item.submenu_mobile && (
                <>
                  <ListItem button>
                    {item.link && <Link scheme="header" href={item?.link} text={item.title} target={item.target} />}
                    {!item.link && <Typography variant="body1">{item.title}</Typography>}
                  </ListItem>
                  <Divider />
                </>
              )}
              {item.submenu_mobile && (
                <List>
                  <ListItem button>
                    {item.link && (
                      <Link scheme="header" variant="h6" href={item?.link} text={item.title} target={item.target} />
                    )}
                    {!item.link && (
                      <Typography variant="h6" align="center" gutterBottom>
                        {item.title}
                      </Typography>
                    )}
                  </ListItem>
                  <Divider />
                  <RecursiveMenu data={item.children} />
                  <Divider />
                </List>
              )}
            </>
          ))}
          {/* <RecursiveMenu data={menu} /> */}
        </List>
      </div>
    )
  }

  const handleClick = (event, id) => {
    if (menuPosition) {
      return
    }
    event.preventDefault()
    setMenuPosition({
      id,
      top: event.pageY,
      left: event.pageX,
    })
  }

  const handleItemClick = () => {
    setMenuPosition(null)
  }

  const DropdownMenuRecursive = ({ items, currentLink, initialLink }) => {
    return (
      <>
        {items?.map((item, index) => {
          return (
            <div key={item?.title + item?.link + index}>
              {item.link && (
                <NavLink className={classes.submenuItem} to={item.link} push={true} target={item.target}>
                  {Boolean(item?.children) ? (
                    <NestedMenuItem
                      label={
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            '&:hover': { backgroundColor: theme.palette.header.text },
                          }}
                        >
                          <span>{item?.title}</span>
                          <ArrowRightIcon className={classes.arrowIcon} fontSize="small" />
                        </div>
                      }
                      parentMenuOpen={!!menuPosition}
                      onClick={handleItemClick}
                      rightIcon={false}
                      MenuProps={{
                        elevation: 0,
                        className: classes.submenu,
                      }}
                    >
                      <DropdownMenuRecursive
                        currentLink={item.link}
                        items={item?.children}
                        handleItemClick={handleItemClick}
                        menuPosition={menuPosition}
                      />
                    </NestedMenuItem>
                  ) : (
                    <MenuItem onClick={handleItemClick}>{item?.title}</MenuItem>
                  )}
                </NavLink>
              )}
              {!item.link && (
                <Typography className={classes.submenuItem} variant="body2">
                  {Boolean(item?.children) ? (
                    <NestedMenuItem
                      label={
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            '&:hover': { backgroundColor: theme.palette.header.text },
                          }}
                        >
                          <span>{item?.title}</span>
                          <ArrowRightIcon className={classes.arrowIcon} fontSize="small" />
                        </div>
                      }
                      parentMenuOpen={!!menuPosition}
                      onClick={handleItemClick}
                      rightIcon={false}
                      MenuProps={{
                        elevation: 0,
                        className: classes.submenu,
                      }}
                    >
                      <DropdownMenuRecursive
                        currentLink={item.link}
                        items={item?.children}
                        handleItemClick={handleItemClick}
                        menuPosition={menuPosition}
                      />
                    </NestedMenuItem>
                  ) : (
                    <MenuItem onClick={handleItemClick}>{item?.title}</MenuItem>
                  )}
                </Typography>
              )}
            </div>
          )
        })}
      </>
    )
  }

  const DropdownMenu = ({ data, id, link }) => {
    return (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={!!menuPosition && menuPosition?.id === id}
        onClose={() => setMenuPosition(null)}
        className={classes.menu}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        elevation={0}
        square
        MenuListProps={{
          onMouseLeave: () => setMenuPosition(null),
        }}
      >
        <DropdownMenuRecursive items={data} initialLink={link} />
      </Menu>
    )
  }

  const handleTerm = (e) => {
    setTerm(e.currentTarget.value)
  }

  const goToSearch = (e) => {
    e.preventDefault()
    if (term.length > 3) history.replace(`/busca/${term}`)
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <AppBar position="static" color="inherit" className={classes.bar}>
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
              <div className={classes.sectionMobile} style={{ width: '100%' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item sm>
                    <IconButton
                      edge="end"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleMobileMenuOpen}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                  <Grid item sm={8}>
                    <div className={classes.logo}>
                      <NavLink to="/">
                        {!dataShop || (!dataShop.logo && <CircularProgress />)}
                        {dataShop.logo && (
                          <img
                            src={dataShop.logo}
                            alt={dataShop.nome}
                            className={classes.logoImage}
                            style={{ width: 180 }}
                          />
                        )}
                      </NavLink>
                    </div>
                  </Grid>
                  <Grid item sm style={{ paddingRight: 14 }}>
                    <CartNotify />
                  </Grid>
                </Grid>
              </div>
              <Hidden smDown>
                <Grid container item xs={10} sm={2} justify="center" alignItems="center">
                  <div className={classes.logo}>
                    <NavLink to="/">
                      {!dataShop || (!dataShop.logo && <CircularProgress />)}
                      {dataShop.logo && (
                        <img
                          src={dataShop.logo}
                          alt={dataShop.nome}
                          className={classes.logoImage}
                          style={{ width: 180 }}
                        />
                      )}
                    </NavLink>
                  </div>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={7}>
                <form className={classes.search} onSubmit={goToSearch}>
                  <InputBase
                    placeholder="Buscar produtos..."
                    value={term}
                    onChange={handleTerm}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                  <IconButton type="submit" className={classes.searchIcon} aria-label="busca">
                    <SearchIcon />
                  </IconButton>
                </form>
              </Grid>
              <div className={classes.sectionDesktop}>
                <DropdownMenuUser />
              </div>
            </Grid>
          </Toolbar>
          <div className={classes.menu2}>
            <Toolbar className={classes.toolbar2}>
              <Grid
                container
                justifyContent={displayMenu?.length > 4 ? 'space-between' : 'flex-start'}
                alignItems="center"
              >
                {displayMenu?.map((item) => {
                  return Boolean(item?.children?.length) ? (
                    <div className={classes.containerLinks} key={item.title}>
                      {item.link && (
                        <Link
                          scheme="header"
                          href={item.link}
                          text={item.title}
                          target={item.target}
                          className={classes.toolbarLinks}
                        />
                      )}
                      {!item.link && (
                        <Typography
                          onClick={(event) => handleClick(event, item.title)}
                          variant="body2"
                          component={'span'}
                          className={classes.toolbarLinks}
                        >
                          {item.title}
                        </Typography>
                      )}

                      <ArrowDropDownIcon
                        onClick={(event) => handleClick(event, item.title)}
                        color="primary"
                        style={{ cursor: 'pointer' }}
                        className={classes.arrowIcon}
                      />
                      <DropdownMenu data={item.children} id={item.title} link={item.link} />
                    </div>
                  ) : (
                    <div className={classes.containerLinks} key={item.title}>
                      <Link
                        scheme="header"
                        key={item.title}
                        href={item.link}
                        text={item.title}
                        target={item.target}
                        className={classes.toolbarLinks}
                      />
                    </div>
                  )
                })}
                {menu?.length > 7 && (
                  <div className={classes.containerLinks} key="more">
                    <span className={classes.toolbarLinks} onClick={(event) => handleClick(event, 'More')}>
                      Mais
                    </span>
                    <ArrowDropDownIcon
                      onClick={(event) => handleClick(event, 'More')}
                      color="primary"
                      style={{ cursor: 'pointer' }}
                      className={classes.arrowIcon}
                    />
                    <DropdownMenu data={menu.slice(7)} id="More" />
                  </div>
                )}
              </Grid>
            </Toolbar>
          </div>
        </AppBar>
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          anchor="left"
          open={menuDrawerOpen}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <DrawerList />
        </Drawer>
      </div>
    </Fragment>
  )
}

Header.propTypes = {
  termSearch: PropTypes.string,
}

export default Header
