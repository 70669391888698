import { TextField } from '@material-ui/core'
import propTypes from 'prop-types'
import React, { Fragment } from 'react'
import NumberFormat from 'react-number-format'

function FormatCPF(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format="###.###.###-##"
    />
  )
}

FormatCPF.propTypes = {
  inputRef: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
}

const CPFInput = (props) => {
  return (
    <Fragment>
      <TextField
        InputProps={{
          inputComponent: FormatCPF,
        }}
        {...props}
      />
    </Fragment>
  )
}

export default CPFInput
